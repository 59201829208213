<template>
  <svg style="width: 0;height: 0; position: absolute">
    <linearGradient id="liner" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="0%" style="stop-color:#BAF1E1;stop-opacity:1" />
      <stop offset="100%" style="stop-color:#229D87;stop-opacity:1" />
    </linearGradient>
	</svg>
</template>

<script>
export default {

}
</script>

<style>

</style>