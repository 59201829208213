// IframePlugin.js
import Vue from "vue";
import IframeContainer from "@/components/iframeJs/IframeContainer.vue";

const IframePlugin = {
  install(Vue) {
    const IframeConstructor = Vue.extend(IframeContainer);
    const instance = new IframeConstructor();
    instance.$mount(document.createElement("div"));
    document.body.appendChild(instance.$el);

    Vue.prototype.$iframe = {
      open: instance.openIframe,
      close: instance.closeIframe
    };
  }
};

export default IframePlugin;
