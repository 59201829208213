<template>
  <el-dialog
      :visible.sync="isShow"
      :modal-append-to-body="false"
      custom-class="el-dialog-transparent2 el-dialog-center2"
      @close="hide"
      width="auto"
      z-index="3000"
    >
      <div class="wapper">
        <img src="@/assets/images/msg-laba.png" class="top-img">
        <div class="title">{{ $t(title) }}</div>
        <div class="num">{{ $t(msg) }}</div>
        <el-button class="button button_blue" @click="submit">{{ $t("确定") }}</el-button>
      </div>
    </el-dialog>
</template>

<script>
export default {
  data(){
    return {
      isShow:false,
      title:"",
      msg:"",
      reslove:()=>{}
    }
  },
  methods:{
    hide(){
      this.reslove(false)
      this.isShow = false
    },
    submit(){
      this.reslove(true)
      this.isShow = false
    },
    show({title,msg}){
      console.log(123456)
      this.title = title
      this.msg = msg
      this.isShow = true
      return new Promise((reslove)=>{
        this.reslove = reslove
      })
    }
  }
}
</script>

<style scoped lang="less">
.wapper{
  background-image: linear-gradient(to bottom,#016A52 ,#009A7A 20%,#009A7A);
  color: #FFF;
  width: 340px;
  max-width: calc(100vw - 30px);
  border-radius: 20px;
  padding: 0 34px;
  text-align: center;
  padding-bottom: 20px;
  @media (min-width:769px) {
    width: 400px;
  }
}

.top-img{
  width: 252px;
  margin-top: -150px;
  margin-left: -40px;
  @media (max-width:768px) {
    width: 200px;
    margin-top: -100px;
  }
}
.button{
  width: 100%;
  border-radius: 100vh;
  margin-top: 20px;
  margin-bottom: 14px;
}
.num{
  color: #FFE3A1;
  font-size: 16px;
  margin-top: 13px;
  padding: 20px 0;
  @media (max-width:768px) {
    padding: 10px 0;
  }
}
.title{
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
}
.cance{
  cursor: pointer;
  color: #AED9CC;
  font-weight: bold;
}
</style>