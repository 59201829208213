<template>
  <el-drawer
    :visible.sync="drawer"
    direction="btt"
    :with-header="false"
    :modal="true"
    :wrapperClosable="true"
    z-index="2003"
  >
    <div class="pwa-pop">
      <img :src="logo" class="pwa-logo" />
      <div class="pwa-content">
        <div class="pwa-desc">{{ $t("pwa应用安装提示") }}</div>
        <div class="btn-list">
          <el-button class="btn-one" @click="hide">{{ $t("取消") }}</el-button>
          <el-button class="btn-colour" @click="install">{{ $t("继续") }}</el-button>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      logo: process.env.VUE_APP_APPLE_ICON,
      flag:true
    };
  },
  watch:{
    "$store.state.pwa.pwaEvent":{
      handler(v){
        if(this.flag && v) {
          this.show()
          this.flag = false   
        }
      },
      immediate:true
    }
  },
  methods: {
    hide(){
      this.drawer = false
    },
    show(){
      if(window.innerWidth>768) return
      this.drawer = true
    },
    install(){
      this.hide()
      this.$store.state.pwa.pwaEvent && this.$store.state.pwa.pwaEvent.prompt();
    }
  },
};
</script>

<style scoped lang="less">
::v-deep .el-drawer {
  border-radius: 6px 6px 0 0;
  background: linear-gradient(180deg, #289662 0%, #00392c 100%) !important;
  height: auto !important;
  @media (min-width: 769px) {
    max-width: 600px;
    margin: 0 auto 30px;
    border-radius: 6px;
  }
}
.pwa-pop {
  padding: 20px 15px 30px;
  display: flex;
}
.pwa-desc {
  margin-bottom: 13px;
  font-size: 13px;
  flex: 1;
}
.pwa-logo {
  width: 105px;
  height: 105px;
  border-radius: 6px;
  flex-shrink: 0;
  margin-right: 15px;
}
.pwa-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.btn-list {
  display: flex;
  .el-button {
    flex: 1;
  }
}
.btn-one {
  background-color: transparent !important;
  border: 1px solid #ffd700 !important;
  color: #ffd700 !important;
}
</style>
