const state = {
  pddData:null
}
const mutations ={
  changeData(state,data){
    state.pddData = data
  }
}

export default {
  namespaced :true,
  state,
  mutations
}