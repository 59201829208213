<!-- IframeContainer.vue -->
<template>
    <div v-show="showIframe" class="iframe-container">
      <div class="iframe-header">
        <button @click="closeIframe">关闭</button>
      </div>
      <iframe :src="src" frameborder="0" allowfullscreen></iframe>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showIframe: false,
        src: ""
      };
    },
    methods: {
      closeIframe() {
        this.showIframe = false;
        this.src = "";
      },
      openIframe(src) {
        this.showIframe = true;
        this.src = src;
      }
    }
  };
  </script>
  
  <style scoped>
  .iframe-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    z-index: 9999;
  }
  
  .iframe-header {
    padding: 10px;
    background: linear-gradient(180deg, #46CA52, #006852);
    text-align: right;
  }
  
  iframe {
    width: 100%;
    height: calc(100% - 40px);
  }
  </style>
  