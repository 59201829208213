import request from '@/utils/request'

/**
 * @param {Object} data
 */

// 在线时长任务
export function online_data_api() {
    return request({
        url: '/lottery/onlineData',
        method: 'get'
    })
}

// 在线转盘执行
export function online_api() {
    return request({
        url: '/lottery/online',
        method: 'get'
    })
}