const state = {
  eggPopIsShow:true
}
const mutations ={
  changeEggPop(state,data){
    state.eggPopIsShow = data
  }
}

export default {
  namespaced :true,
  state,
  mutations
}