<template>
  <div v-if="$t('service_id') !== 'service_id'">
    <LiveChatWidget
      :license="$t('service_id')"
      :visibility="visibility"
      @visibility-changed="visibilityChanged"
    />
    <div
      class="livechat-icon"
      @click="visibility = 'maximized'"
      v-if="$route.path === '/'"
    >
      <img src="@/assets/images/livechat.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { LiveChatWidget } from "@livechat/widget-vue/v2";
import { EventBus } from "@/common/eventBus.js";
export default {
  data() {
    return {
      visibility: "hidden",
    };
  },
  components: {
    LiveChatWidget,
  },
  mounted() {
    EventBus.$on("liveChat", () => {
      this.visibility = "maximized";
    });
  },
  methods: {
    visibilityChanged(e) {
      this.visibility = e.visibility;
    },
  },
};
</script>
<style scoped lang="less">
.livechat-icon {
  width: 45px;
  height: 45px;
  position: fixed;
  z-index: 99;
  right: 25px;
  bottom: 130px;
  cursor: pointer;
  user-select: none;
  @media (min-width: 769px) {
    right: 35px;
    bottom: 120px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
