const state = {
  pwaEvent:null
}
const mutations ={
  setPwa(state,data){
    state.pwaEvent = data
  }
}

export default {
  namespaced :true,
  state,
  mutations
}