<template>
	<div id="app" :class="$t('base_class')">
		
		<keep-alive :include="keepPages">
			<router-view></router-view>
		</keep-alive>
		<msgPop ref="msgPop"></msgPop>
		<giftAmountPop></giftAmountPop>
		<!-- <onlineDuration v-if="$store.state.isLoginStatu" /> -->
		<cookiesDialog />
		<!-- <telegram /> -->

		<svgColors></svgColors>
		<!-- <pwaPop></pwaPop> -->
		<livechat></livechat>
	</div>
</template>
<script>
import msgPop from "@/components/msgPop.vue"
import livechat from "@/components/liveChat.vue";
import giftAmountPop from "@/components/giftAmountPop.vue"
import { getWsToken } from "@/api/user"
import onlineDuration from '@/components/onlineDuration.vue'
import cookiesDialog from '@/components/cookiesDialog.vue'
// import telegram from '@/components/telegram.vue'
import updateLangAndSystem from '@/common/updateLangAndSystem.js';
import svgColors from '@/components/svgColors';
import pwaPop from '@/components/pwaPop';
export default {
	components: {
		msgPop,
		giftAmountPop,
		onlineDuration,
		cookiesDialog,
		svgColors,
		pwaPop,
		livechat
	},
	mounted() {
		this.initGlobalCss()
		window.addEventListener("resize", (e) => {
			this.$store.commit("setWindowWidth")
		})
	},
	methods: {
		// 添加全局css 变量
		initGlobalCss() {
			const el_skeleton__image = `url(${this.$t('placeholder_img') == 'placeholder_img' ? "/" + require('@/assets/images/anwen.png') : this.$t('placeholder_img')})`
			document.documentElement.style.setProperty("--el-skeleton-image", el_skeleton__image)
		},
		async initSocket() {
			const _this = this
			await getWsToken().then(response => {
				if (response.data.code == 1) {
					const res = response.data.data;
					this.$helper.set("socketUrl", res.WebSocket);
					this.$helper.set("socketToken", res.token);
					this.$helper.set("socketUid", res.uid);
					const url = res.WebSocket;
					if (url != "") {
						_this.$ws.initWebSocket(_this.receiveMessage, url, () => {
							console.log("发送")
							_this.$ws.send({
								type: "online",
								op: "page",
								page: this.$route.path
							})
						});
					}
				}
			});
		},
		receiveMessage(e) {
			// console.log(e, (new Date()));
			if (!e) {
				return;
			}
			const params = this.$helper.parseMsg(e.data);
			if (params && params["order"] == "msg") {
				params["title"] = decodeURIComponent(params["title"]);
				params["msg"] = decodeURIComponent(params["msg"]);
				switch (params["mode"]) {
					case "recharge":
						var content = JSON.parse(params['msg'])
						this.tips(this.$t(content['msg']) + content['amount'], params["title"], params['showtime'], require('@/assets/images/msg-icon3.png'))
						this.addUserBalance(content.amount)
						break;
					case "activity":
						var content = JSON.parse(params['msg'])
						this.tips(this.$t(content['msg']) + content['amount'], params["title"], params['showtime'], require('@/assets/images/msg-icon4.png'))
						this.addUserBalance(content.amount)
						break;
					case "pop":
						this.$refs.msgPop.show({
							title: params["title"],
							msg: params["msg"]
						})
						break;
					case "withdraw":
						this.tips(params["msg"], params["title"], params['showtime'], require('@/assets/images/msg-icon2.png'))
						break;
					default:
						this.tips(params["msg"], params["title"], params['showtime'])
				}
			}
		},
		watchRouterAndSendWs(to, from) {
			// 监听路由并用ws向后台传输,只记录路径
			if (to.path != from.path) {
				this.$ws.send({
					type: "online",
					op: "page",
					page: to.path
				})
			}
			// 监听游戏界面路由，向后台传输
			if (to.path == "/game-detail") {
				this.$ws.send({
					type: "online",
					op: "playgame",
					gameid: to.query.id
				})
			}
		}
	},
	watch: {
		$route(to, from) {
			this.watchRouterAndSendWs(to, from)
			if (!this.$store.state.blanceStatus) {
				this.$store.commit("$vuexSetBlanceShowState", true);
			}
		},
		"$store.state.isLoginStatu": {
			async handler(v) {
				// 更新系统配置和语言包
				updateLangAndSystem({i18n:this.$i18n,data:{}})
				if (v) {
					await this.initSocket()
				} else {
					this.$ws.close()
				}
			},
		}
	},
	computed: {
		keepPages() {
			return this.$store.getters.getKeepPages.split(',');
		}
	}
}
</script>

<style>
@import './assets/fonts/iconfont.css';
</style>
