<template>
    <div v-if="showPopup && $store.state.receiveState" v-show="dialogShow">
        <div v-if="showTips" class="reward-popup" @click.self="closePopup">
            <div class="popup-content">
                <img class="duration-bg" src="@/assets/images/duration.png" alt="">
                <div class="duration-content">
                    <p class="withdrawAlert">{{ $t('在线时长奖励') }}</p>
                    <p class="duration-tips">{{ $t("成功领取在线时长奖励,x等级轮盘一次") }}</p>
                </div>
                <div class="operate-wrap">
                    <button type="button" class="jump-btn" @click="jumpPage">{{ $t("前往抽奖") }}</button>
                    <div @click="closePopup" style="cursor: pointer;">
                        <img class="cs-btn" src="@/assets/images/b-close.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="gift-postion" @click="openDialog">
                <span>{{  }}</span>
                <img v-if="countdown > 0" src="@/assets/images/online-rewards.png" alt="">
                <img v-else class="complete-status" src="@/assets/images/online-rewards-act.png" alt="">
                <p>{{ formattedTime }}</p>
            </div>
        </div>
    </div>
</template>
    
<script>
import { online_data_api, online_api } from "@/api/online";
export default {
    data() {
        return {
            showPopup: false,
            showTips: false,
            countdown: 0,
            timer: null,
            timerId: null,
            completeState: false,
            isPageVisible: true,
            state: 0,
            cacheTime: 0
        };
    },
    mounted() {
        this.fetchDate()
        document.addEventListener("visibilitychange", this.handleVisibilityChange);

    },
    beforeDestroy() {
        this.clearTimerId()
        this.clearTimes();
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    },
    methods: {
        async handleVisibilityChange() {
            this.isPageVisible = document.visibilityState;
            if (this.isPageVisible == "visible") {
                // 用户回到页面的逻辑
                this.fetchDate()
            } else if (this.isPageVisible == "hidden") {
                // 用户离开页面的逻辑
                this.clearTimes();
                this.clearTimerId()
            }
        },
        openDialog() {
            if (this.countdown > 0) return
            this.showTips = true
        },
        jumpPage() {
            this.showTips = false
            this.$store.state.onlineRewardsShow = true
            this.$store.state.receiveState = false
            this.fetchDate() //再次请求，防止有多次在线奖励
        },
        closePopup() {
            this.showTips = false;
        },
        startCountdown() {
            if (!this.timer) {
                this.timer = window.setInterval(() => {
                    if (this.countdown > 0) {
                        this.countdown--;
                    } else {
                        if (this.status = 1) {
                            this.clearTimes()
                        } else {
                            this.countdown = this.cacheTime
                        }
                    }
                }, 1000);
            }
        },
        async fetchDate() {
            const { data } = await online_data_api()
            if (data.data.time) {
                this.showPopup = true
                this.$store.state.receiveState = true
                this.countdown = data.data.time || 0
                this.startCountdown();
                this.fetchOnlineStatus()
            }

        },
        async fetchOnlineStatus() {
            const { data } = await online_api()
            this.state = data.data.status
            this.cacheTime = data.data.time
            if (data.data.status === 0) {
                this.timerId = setTimeout(() => {
                    this.fetchOnlineStatus();
                }, 3000)
            } else if (data.data.status === 1) {
                this.completeState = true
            }
        },
        clearTimerId() {
            if (this.timerId) {
                clearTimeout(this.timerId);
                this.timerId = null;
            }
        },
        clearTimes() {
            if (this.timer) {
                window.clearInterval(this.timer);
                this.timer = null
            }
        }
    },
    computed: {
        formattedTime() {
            const hours = Math.floor(this.countdown / 3600);
            const minutes = Math.floor((this.countdown % 3600) / 60);
            const seconds = this.countdown % 60;
            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        },
        dialogShow(){
            const list = ['/','/game-collect','/all-game']
            return list.includes(this.$route.path)
        }
    },
};
</script>
    
<style lang="less" scoped>
.reward-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    padding: 0 15px;
    z-index: 99999;
}

.popup-content {
    position: relative;
    width: 100%;
    max-width: 710px;
    border-radius: 5px;
    // border: 1px solid red;
    text-align: center;
}

.withdrawAlert {
    font-size: 42px;
    color: #FFF8D8;
    text-align: center;
    padding: 10px 0;

    @media (max-width:768px) {
        font-size: 34px;
    }
}

.duration-tips {
    font-size: 18px;

    @media (max-width:768px) {
        font-size: 12px;
    }
}

.duration-content {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    padding: 12px 20px;

}

.duration-bg {
    padding-top: 0;
    width: 100%;
    height: auto;
    @media (max-width:572px) {
        padding-top: 40px;
    }
}

.operate-wrap {
    position: absolute;
    width: 100%;
    bottom: -10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width:572px) {
        bottom: -50px;
    }
}

.jump-btn {
    width: 100%;
    max-width: 280px;
    margin-bottom: 20px;
    padding: 15px 0;
    background: linear-gradient(0deg, #4C248E, #AC7CED);
    border: 1px solid rgba(255, 255, 255, 0.45);
    border-radius: 38px;
    cursor: pointer;

    @media (max-width:572px) {
        width: 70%;
        padding: 12px 0;
    }
}

.cs-btn {
    width: 32px;
    height: auto;
    cursor: pointer;
    z-index: 99;

    @media (max-width:572px) {
        width: 24px;
    }
}

.gift-postion {
    width: 60px;
    position: fixed;
    z-index: 990;
    right: 30px;
    bottom: 60px;
    text-align: center;
    color: #83FE9B;
    font-size: 14px;
    line-height: 7px;
    cursor: pointer;
    user-select: none;

    @media (max-width: 768px) {
        right: 20px;
        bottom: 110px;
    }
}

.complete-status {
    animation: shake 5s ease infinite;
}

@keyframes shake {

    0%,
    20%,
    100% {
        transform: translateX(0);
        /* 初始和结束时图标位置不变 */
    }

    10% {
        transform: translateY(8px);
        /* 在抖动的中间位置时，向右偏移10px */
    }
}
</style>